.indicator-base-margin {
    margin-right: var(--eds-space-12);
}

.updated-at {
    vertical-align: bottom;
}

.error-indicator-container {
    display: flex;
    align-items: baseline;
    position: relative;

    .error-indicator .info-icon {
        position: relative;
        top: -5px;
        right: -2px;
        cursor: pointer;
        color: var(--eds-color-background-danger);
        background-color: transparent;
        font-weight: 500;
    }
}