#action-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-top: var(--eds-space-16);
}

#action-button-container {
    > span {
        margin-right: var(--eds-space-8);
        background-color: transparent;
        color: var(--eds-color-text-secondary);
    }
}