#batch-table {
    width: 100%;
}

.table-action-header {
    position: relative;
    top: var(--eds-space-4);
}

.table-action-data, .table-action-header {
    padding: var(--eds-space-8) var(--eds-space-8) var(--eds-space-8) var(--eds-space-16) !important;
}

.table-data, .table-header {
    padding: var(--eds-space-12) !important;
}

.hidden {
    display: none;
}

.text-center {
    text-align: center;
}