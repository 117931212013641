#main-container > * {
    margin-bottom: var(--eds-space-12);
}

#loader {
    height: 200px;
    position: relative;
}

#loader > * {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -25px 0 0 -25px
}

.eds-button .eds-icon--close {
    color: var(--eds-color-text-secondary);
}